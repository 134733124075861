<template>
    <a-drawer
        ref="addInvestProjectDrawer"
        title="Добавить инвестпроект"
        placement="right"
        :width="drawerWidth"
        :visible="visible"
        destroyOnClose
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <a-form-model
            ref="investForm"
            class="invest_form"
            :model="form"
            :rules="rules">
            <div class="form_block">
                <div class="form_block__header">
                    <h3>Основная информация о проекте</h3>
                    <div class="st">Шаг 1</div>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="project_name" label="Наименование проекта" prop="project_name">
                        <a-input
                            v-model="form.project_name"
                            placeholder="Напишите наименование проекта"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="organization" label="Организация" prop="organization">
                        <a-select
                            v-model="form.organization"
                            placeholder="Организация"
                            size="large"
                            class="w-full"
                            :loading="orgLoading"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null">
                            <a-select-option 
                                v-for="org in organization.results" 
                                :key="org.id" 
                                :value="org.id">
                                {{ org.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <a-form-model-item ref="location" label="Местоположение" prop="location">
                    <div v-if="locationLabel" class="name">{{ locationLabel }}</div>
                    <div v-else class="name text-gray-300">Не указана</div>
                </a-form-model-item>
                <div class="grid gap-x-0 gap-y-0 md:gap-x-6 md:gap-y-0 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="location_region" label="Область/Город республиканского значения" prop="location_region">
                        <a-select
                            v-model="locationRegion"
                            allowClear
                            placeholder="Выберите регион проекта"
                            size="large"
                            class="w-full"
                            :loading="locationRegionLoading"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @dropdownVisibleChange="locationRegionOpenSelect"
                            @change="locationRegionChange">
                            <a-select-option 
                                v-for="region in locationRegionList" 
                                :key="region.id" 
                                :value="region.id">
                                {{ region.code }} - {{ region.rus_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="location_district" label="Район/Город областного значения" prop="location_district">
                        <a-select
                            v-model="locationDistrict"
                            allowClear
                            :disabled="locationRegion ? false : true"
                            placeholder="Выберите район/город областного значения"
                            size="large"
                            class="w-full"
                            :loading="locationDistrictLoading"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @dropdownVisibleChange="locationDistrictOpenSelect"
                            @change="locationDistrictChange">
                            <a-select-option 
                                v-for="district in locationDistrictList" 
                                :key="district.id" 
                                :value="district.id">
                                {{ district.code }} - {{ district.rus_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="location_akimat" label="Акимат" prop="location_akimat">
                        <a-select
                            v-model="locationAkimat"
                            allowClear
                            :disabled="locationDistrict ? false : true"
                            placeholder="Выберите акимат"
                            size="large"
                            class="w-full"
                            :loading="locationAkimatLoading"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @dropdownVisibleChange="locationAkimatOpenSelect"
                            @change="locationAkimatChange">
                            <a-select-option 
                                v-for="akimat in locationAkimatList" 
                                :key="akimat.id" 
                                :value="akimat.id">
                                {{ akimat.code }} - {{ akimat.rus_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="location_settlement" label="Поселение" prop="location_settlement">
                        <a-select
                            v-model="locationSettlement"
                            allowClear
                            :disabled="locationAkimat ? false : true"
                            placeholder="Выберите поселение"
                            size="large"
                            class="w-full"
                            :loading="locationSettlementLoading"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @dropdownVisibleChange="locationSettlementOpenSelect"
                            @change="locationSettlementChange">
                            <a-select-option 
                                v-for="settlement in locationSettlementList" 
                                :key="settlement.id" 
                                :value="settlement.id">
                                {{ settlement.code }} - {{ settlement.rus_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="location_settlement_2" label="Село" prop="location_settlement_2">
                        <a-select
                            v-model="locationVillage"
                            allowClear
                            :disabled="locationSettlement ? false : true"
                            placeholder="Выберите село"
                            size="large"
                            class="w-full"
                            :loading="locationVillageLoading"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @dropdownVisibleChange="locationVillageOpenSelect"
                            @change="locationVillageChange">
                            <a-select-option 
                                v-for="village in locationVillageList" 
                                :key="village.id" 
                                :value="village.id">
                                {{ village.code }} - {{ village.rus_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="company_bin" label="БИН предприятия" prop="company_bin">
                        <a-input
                            v-model="form.company_bin"
                            placeholder="БИН предприятия"
                            :maxLength="12"
                            size="large"
                            @change="binChange">
                            <template v-if="binLoading" slot="suffix">
                                <a-spin size="small" />
                            </template>
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item ref="company_name" label="Наименование предприятия" prop="company_name">
                        <a-input
                            v-model="form.company_name"
                            placeholder="Наименование предприятия"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="category" label="Категория" prop="category">
                        <a-select 
                            v-model="form.category"
                            placeholder="Укажите направление" 
                            size="large" 
                            class="w-full"
                            :default-active-first-option="false"
                            :not-found-content="null"
                            :loading="categoryLoading"
                            @change="categoryChange">
                            <a-select-option 
                                v-for="category in categoryList.results" 
                                :key="category.id" 
                                :value="category.id">
                                {{ category.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="subcategory" label="Подкатегория" prop="subcategory">
                        <a-select 
                            v-model="form.subcategory"
                            :disabled="form.category ? false : true"
                            placeholder="Укажите отрасль"
                            size="large" 
                            class="w-full"
                            :default-active-first-option="false"
                            :not-found-content="null"
                            :loading="subcategoryLoading">
                            <a-select-option 
                                v-for="subcategory in subcategoryList.results" 
                                :key="subcategory.id" 
                                :value="subcategory.id">
                                {{ subcategory.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1">
                    <a-form-model-item ref="comment" label="Комментарий" prop="comment">
                        <a-textarea
                            v-model="form.comment"
                            :maxLength="1000"
                            :auto-size="{ minRows: 3, maxRows: 8 }"
                            placeholder="Добавьте комментарий"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="company_director_name" label="ФИО руководителя предприятия" prop="company_director_name">
                        <a-input
                            v-model="form.company_director_name"
                            placeholder="ФИО руководителя предприятия"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="company_phone" label="Контактный номер телефона" prop="company_phone">
                        <a-input
                            v-model="form.company_phone"
                            placeholder="Контактный номер телефона"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="stage" label="Стадия проекта" prop="stage">
                        <a-select 
                            v-model="form.stage"
                            placeholder="Укажите стадию проекта" 
                            size="large" 
                            class="w-full"
                            :default-active-first-option="false"
                            :not-found-content="null"
                            :loading="stageLoading"
                            @dropdownVisibleChange="stageOpenSelect">
                            <a-select-option 
                                v-for="stage in stageList.results" 
                                :key="stage.id" 
                                :value="stage.id">
                                {{ stage.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1">
                    <a-form-model-item ref="foreign_investor_info" label="Иностранный инвестор" prop="foreign_investor_info">
                        <a-textarea
                            v-model="form.foreign_investor_info"
                            :maxLength="1000"
                            :auto-size="{ minRows: 3, maxRows: 8 }"
                            placeholder="Укажите иностранного инвестора (при наличии)"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="project_capacity" label="Мощность проекта" prop="project_capacity">
                        <a-input-number
                            v-model="form.project_capacity"
                            :step="0.01"
                            class="w-full"
                            placeholder="Укажите мощность проекта"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="measure_unit" label="Единица измерения" prop="measure_unit">
                        <a-select 
                            v-model="form.measure_unit"
                            placeholder="Единица измерения" 
                            size="large" 
                            class="w-full"
                            :default-active-first-option="false"
                            :not-found-content="null"
                            :loading="measureUnitLoading">
                            <a-select-option 
                                v-for="unit in measureUnitList.results" 
                                :key="unit.id" 
                                :value="unit.code">
                                {{ unit.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="date_start" label="Дата начала проекта" prop="date_start">
                        <a-date-picker v-model="form.date_start" valueFormat="YYYY-MM-DD" :showToday="false" size="large" placeholder="Дата начала проекта" class="w-full" />
                    </a-form-model-item>
                    <a-form-model-item ref="dead_line" label="Планируемый срок ввода в эксплуатацию (месяц, год)" prop="dead_line">
                        <a-date-picker v-model="form.dead_line" valueFormat="YYYY-MM-DD" :showToday="false" size="large" placeholder="Планируемый срок ввода в эксплуатацию" class="w-full" />
                    </a-form-model-item>
                </div>
            </div>
            <div class="form_block">
                <div class="form_block__header">
                    <h3>Стоимость проекта</h3>
                    <div class="st">Шаг 2</div>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="funds" label="Вся стоимость проекта, млн. тенге" prop="funds">
                        <a-input-number
                            v-model="form.funds"
                            :step="0.01"
                            class="w-full"
                            placeholder="Вся стоимость проекта"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="funding_sources_list" :class="form.funding_sources.length && 'mb-5'">
                    <div 
                        v-for="(source, index) in form.funding_sources" 
                        :key="source.key"
                        class="equipment_list__item">
                        <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                            <a-form-model-item 
                                label="Источник финансирования" 
                                :prop="'funding_sources.' + index + '.funding_source'"
                                :rules="{
                                    required: true,
                                    message: $t('wgr.field_require'),
                                    trigger: 'blur',
                                }">
                                <a-select 
                                    v-model="source.funding_source" 
                                    placeholder="Укажите источник финансирования" 
                                    size="large" 
                                    class="w-full"
                                    :default-active-first-option="false"
                                    :not-found-content="null"
                                    show-search
                                    :filter-option="filterOption"
                                    :loading="sourceLoading"
                                    @dropdownVisibleChange="sourceOpenSelect">
                                    <a-select-option 
                                        v-for="iSource in fundingSourceList.results" 
                                        :key="iSource.id" 
                                        :value="iSource.id">
                                        {{ iSource.string_view }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <a-form-model-item 
                                :prop="'funding_sources.' + index + '.amount'" 
                                label="Объем финансирования, млн. тенге"
                                :rules="{
                                    required: true,
                                    message: $t('wgr.field_require'),
                                    trigger: 'blur',
                                }">
                                <a-input-number
                                    v-model="source.amount"
                                    :step="0.01"
                                    class="w-full"
                                    placeholder="Укажите объем финансирования"
                                    size="large" />
                            </a-form-model-item>
                        </div>
                        <div class="grid gap-0 md:gap-6 grid-cols-1">
                            <a-form-model-item 
                                :prop="'funding_sources.' + index + '.comment'"  
                                label="Комментарий"
                                :rules="{
                                    required: false,
                                    message: $t('wgr.field_require'),
                                    trigger: 'blur',
                                }">
                                <a-textarea
                                    v-model="source.comment"
                                    :maxLength="1000"
                                    :auto-size="{ minRows: 3, maxRows: 8 }"
                                    placeholder="Добавьте короткий комментарий"
                                    size="large" />
                            </a-form-model-item>
                        </div>
                        <div v-if="form.funding_sources.length > 1" style="margin-top: -10px;">
                            <a-button type="link" size="small" @click="removeSource(index)">
                                Удалить
                            </a-button>
                        </div>
                    </div>
                </div>
                <a-button 
                    type="primary" 
                    ghost 
                    block 
                    size="large"
                    @click="addSource()">
                    Добавить источник финансирования
                </a-button>
            </div>
            <div class="form_block">
                <div class="form_block__header">
                    <h3>Этап реализации</h3>
                    <div class="st">Шаг 3</div>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="has_documentation" label="ПСД" prop="has_documentation">
                        <a-select v-model="form.has_documentation" size="large" class="w-full">
                            <a-select-option value="true">
                                Разработана
                            </a-select-option>
                            <a-select-option value="false">
                                Не разработана
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="installation_stage" label="Стадия СМР, %" prop="installation_stage">
                        <a-input-number
                            v-model="form.installation_stage"
                            class="w-full"
                            :min="0"
                            :max="100"
                            placeholder="Стадия СМР, %"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1">
                    <a-form-model-item ref="infrastructure_info" label="Проведенные инфраструктуры" prop="infrastructure_info">
                        <a-textarea
                            v-model="form.infrastructure_info"
                            :maxLength="1000"
                            :auto-size="{ minRows: 3, maxRows: 8 }"
                            placeholder="Опишите какая инфраструктура проведена"
                            size="large" />
                    </a-form-model-item>
                </div>
            </div>
            <div class="form_block">
                <div class="form_block__header">
                    <h3>Дополнительная информация</h3>
                    <div class="st">Шаг 4</div>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="jobs_temporary" label="Временные рабочие места" prop="jobs_temporary">
                        <a-input-number
                            v-model="form.jobs_temporary"
                            class="w-full"
                            placeholder="Укажите количество временных рабочих мест"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="jobs_permanent" label="Постоянные рабочие места" prop="jobs_permanent">
                        <a-input-number
                            v-model="form.jobs_permanent"
                            class="w-full"
                            placeholder="Укажите количество постоянных рабочих мест"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="land_plot_is_allocated" label="Земельный участок выделен" prop="land_plot_is_allocated">
                        <a-switch
                            :checked="form.land_plot_is_allocated"
                            size="large"
                            @change="onChange"/>
                    </a-form-model-item>
                    <a-form-model-item ref="land_plot" label="Земельный участок (количество гектар)" prop="land_plot">
                        <a-input-number
                            v-model="form.land_plot"
                            class="w-full"
                            :step="0.01"
                            placeholder="Укажите земельный участок"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="cadaster" label="Кадастровый номер ЗУ" prop="cadaster">
                        <a-input
                            v-model="form.cadaster"
                            placeholder="Кадастровый номер ЗУ"
                            size="large" />
                    </a-form-model-item>
                </div>
            </div>
            <div class="form_block">
                <div class="form_block__header">
                    <h3>Документы проекта</h3>
                    <div class="st">Шаг 5</div>
                </div>
                <div class="form_block__attachments">
                    <a-button
                        class="mt-auto"
                        type="primary"
                        block
                        ghost
                        size="large"
                        @click="openFileModal">
                        Прикрепить документы
                    </a-button>
                    <FileAttach 
                        ref="fileAttach"
                        :zIndex="1100"
                        :attachmentFiles="form.attachments"
                        :maxMBSize="50"
                        createFounder
                        :showDeviceUpload="true"
                        :class="form.attachments.length && 'mt-2 mb-5'"
                        class="ml-2" />
                </div>
                <div class="form_block__add-project">
                    <a-checkbox @change="addProjectOnChange">
                        Создать проект
                    </a-checkbox>
                </div>
            </div>
            <div class="footer_buttons">
                <a-button type="primary" size="large" :loading="loading" @click="formSubmit()">
                    Создать инвест.проект
                </a-button>
                <a-button type="ui" size="large" @click="visible = false">
                    Отмена
                </a-button>
            </div>
        </a-form-model>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
import FileAttach from '@apps/vue2Files/components/FileAttach'
import { mapState } from 'vuex'

let binTimer;
const formData = {
    add_project: false,
    attachments: new Array(),
    cadaster: "",
    category: null,
    comment: '',
    company_bin: "",
    company_director_name: "",
    company_name: "",
    company_phone: "",
    country: null,
    date_start: null,
    dead_line: null,
    foreign_investor_info: "",
    funding_source: null,
    funds: "",
    has_documentation: null,
    infrastructure_info: "",
    installation_stage: "",
    jobs_permanent: 0,
    jobs_temporary: 0,
    land_plot: 0,
    land_plot_is_allocated: false,
    location: null,
    measure_unit: null,
    organization: null,
    pasture_quantity: 0,
    plowed_field_quantity: 0,
    project_capacity: "",
    project_name: "",
    questions: "",
    stage: "",
    srok: "",
    subcategory: null,
    work_experience: "",
    funding_sources: [
        {
            key: Date.now(),
            funding_source: null,
            amount: null,
            comment: ''
        }
    ]
}

export default {
    name: 'AddProject',
    components: {
        FileAttach
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        drawerWidth() {
            if(this.windowWidth > 1100)
                return 1100
            else {
                return '100%'
            }
        }
    },
    data() {
        return {
            visible: false,
            rules: {
                category: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                company_bin: [{ min: 12, max: 12, message: 'Введите 12 символов', trigger: 'blur' }],
                country: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                date_start: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'change' }],
                dead_line: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'change' }],
                funds: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                has_documentation: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                installation_stage: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                location: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'change' }],
                measure_unit: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                organization: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                p_name_equipment: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                project_capacity: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                project_name: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                srok: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }]
            },
            form: {...formData},
            locationLabel: '',
            locationRegion: null,
            locationRegionList: [],
            locationRegionLoading: false,
            locationDistrict: null,
            locationDistrictList: [],
            locationDistrictLoading: false,
            locationAkimat: null,
            locationAkimatList: [],
            locationAkimatLoading: false,
            locationSettlement: null,
            locationSettlementList: [],
            locationSettlementLoading: false,
            locationVillage: null,
            locationVillageList: [],
            locationVillageLoading: false,
            stageLoading: false,
            organization: {
                results: []
            },
            regionLoading: false,
            orgLoading: false,
            districtLoading: false,
            countryLoading: false,
            sourceLoading: false,
            categoryLoading: false,
            subcategoryLoading: false,
            measureUnitLoading: false,
            loading: false,
            binLoading: false,
            fundingSourceList: {
                results: []
            },
            categoryList: {
                results: []
            },
            measureUnitList: {
                results: []
            },
            subcategoryList: {
                results: []
            },
            stageList: {
                results: []
            }
        }
    },
    methods: {
        addProjectOnChange(event) {
            this.form.add_project = event.target.checked
        },
        openFileModal() {
            this.$nextTick(() => {
                this.$refs.fileAttach.openFileModal()
            })
        },
        onChange(checked) {
            this.form.land_plot_is_allocated = checked
        },
        async getSubcategory() {
            if(!this.subcategoryList.results.length) {
                try {
                    this.subcategoryLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectSubcategoryModel",
                        filters: {
                            category: this.form.category
                        }
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.subcategoryList.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.subcategoryLoading = false
                }
            }
        },
        async getMeasureUnit() {
            if(!this.measureUnitList.results.length) {
                try {
                    this.measureUnitLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectMeasureUnitModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.measureUnitList.results = data.selectList
                        this.form.measure_unit = data.selectList[0].code
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.measureUnitLoading = false
                }
            } else
                this.form.measure_unit = this.measureUnitList.results[0].code
        },
        async getOrganization() {
            if(!this.organization.results.length) {
                try {
                    this.orgLoading = true
                    const params = {
                        permission_type: "create_invest_projects_info"
                    }
                    const { data } = await this.$http.get('/contractor_permissions/organizations/', {
                        params
                    })
                    if(data?.length) {
                        this.organization.results = data
                        this.form.organization = data[0].id
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.orgLoading = false
                }
            }
        },
        async getCategory() {
            if(!this.categoryList.results.length) {
                try {
                    this.categoryLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectCategoryModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.categoryList.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.categoryLoading = false
                }
            }
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        formSubmit() {
            this.$refs['investForm'].validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const formData = {...this.form}
                        if(formData.has_documentation === 'true') {
                            formData.has_documentation = true
                        } else {
                            formData.has_documentation = false
                        }
                        if(formData.attachments.length) {
                            formData.attachments = formData.attachments.map(each => each.id)
                        }
                        const { data } = await this.$http.post('/invest_projects_info/', formData)
                        if(data) {
                            this.visible = false
                            this.$message.success('Проект успешно создан')
                            eventBus.$emit('update_invest_list')
                            eventBus.$emit('update_invest_project_statistic')
                        }
                    } catch(error) {
                        console.log(error)
                        this.$message.error('Ошибка сохранения')
                    } finally{
                        this.loading = false
                    }
                } else
                    return false
            })
        },
        removeSource(index) {
            this.form.funding_sources.splice(index, 1)
        },
        addSource() {
            this.form.funding_sources.push({
                key: Date.now(),
                funding_source: null,
                amount: null,
                comment: ''
            })
        },
        binChange(e) {
            clearTimeout(binTimer)
            binTimer = setTimeout(async () => {
                const bin = e.target.value
                if(bin.length === 12) {
                    try {
                        this.binLoading = true
                        const { data } = await this.$http.get('/catalogs/contractor_from_egov/', {
                            params: {
                                bin
                            }
                        })
                        if(data?.success && data.obj) {
                            if(data.obj.fio)
                                this.form.company_director_name = data.obj.fio
                            if(data.obj.name)
                                this.form.company_name = data.obj.name
                        }
                    } catch(e) {
                        console.log(e)
                    } finally {
                        this.binLoading = false
                    }
                }
            }, 300)
        },
        afterVisibleChange(vis) {
            if(!vis) {
                this.form = {...formData}
                this.form.attachments = new Array()
                this.form.funding_sources = [
                    {
                        key: Date.now(),
                        funding_source: null,
                        amount: null,
                        comment: ''
                    }
                ]
                this.organization.results = []
                this.locationLabel = ''
                this.locationRegion = null
                this.locationRegionList = []
                this.locationDistrict = null
                this.locationDistrictList = []
                this.locationAkimat = null
                this.locationAkimatList = []
                this.locationSettlement = null
                this.locationSettlementList = []
                this.locationVillage = null
                this.locationVillageList = []
                this.fundingSourceList.results = []
            } else {
                this.getCategory()
                this.getOrganization()
                this.getMeasureUnit()
            }
        },
        categoryChange() {
            this.form.subcategory = null
            this.subcategoryList = {
                results: []
            }
            this.getSubcategory()
        },
        locationRegionOpenSelect(vis) {
            if(vis)
                this.getLocationRegions()
        },
        stageOpenSelect(vis) {
            if(vis)
                this.getProjectStages()
        },
        locationRegionChange() {
            this.form.location = this.locationRegion
            this.setLocationLabel('region')
            this.locationDistrict = null
            this.locationDistrictList = []
            this.locationAkimat = null
            this.locationAkimatList = []
            this.locationSettlement = null
            this.locationSettlementList = []
            this.locationVillage = null
            this.locationVillageList = []
        },
        locationDistrictOpenSelect(vis) {
            if(vis)
                this.getLocationDistricts()
        },
        locationDistrictChange(val) {
            val ? this.setLocationLabel('district') : this.setLocationLabel('region')
            this.form.location = this.locationDistrict
            this.locationAkimat = null
            this.locationAkimatList = []
            this.locationSettlement = null
            this.locationSettlementList = []
            this.locationVillage = null
            this.locationVillageList = []
        },
        locationAkimatOpenSelect(vis) {
            if(vis)
                this.getLocationAkimats()
        },
        locationAkimatChange(val) {
            val ? this.setLocationLabel('akimat') : this.setLocationLabel('district')
            this.form.location = this.locationAkimat
            this.locationSettlement = null
            this.locationSettlementList = []
            this.locationVillage = null
            this.locationVillageList = []
        },
        locationSettlementOpenSelect(vis) {
            if(vis)
                this.getLocationSettlements()
        },
        locationSettlementChange(val) {
            val ? this.setLocationLabel('settlement') : this.setLocationLabel('akimat')
            this.form.location = this.locationSettlement
            this.locationVillage = null
            this.locationVillageList = []
        },
        locationVillageOpenSelect(vis) {
            if(vis)
                this.getLocationVillages()
        },
        locationVillageChange(val) {
            val ? this.setLocationLabel('village') : this.setLocationLabel('settlement')
            this.form.location = this.locationVillage
        },
        setLocationLabel(location) {
            let loc = null
            switch(location) {
            case 'region':
                loc = this.locationRegionList.find(item => item.id === this.locationRegion)
                break
            case 'district':
                loc = this.locationDistrictList.find(item => item.id === this.locationDistrict)
                break
            case 'akimat':
                loc = this.locationAkimatList.find(item => item.id === this.locationAkimat)
                break
            case 'settlement':
                loc = this.locationSettlementList.find(item => item.id === this.locationSettlement)
                break
            case 'village':
                loc = this.locationVillageList.find(item => item.id === this.locationVillage)
                break
            }
            this.locationLabel = loc ? `${loc.code} - ${loc.rus_name}` : ''
        },
        sourceOpenSelect(vis) {
            if(vis)
                this.getFundingSourcesList()
        },
        async getLocations(parent) {
            return new Promise((resolve, reject) => {
                this.$http.get('/invest_projects_info/location', { params: { parent: parent} })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        async getLocationRegions() {
            if(this.locationRegionList.length !== 0) 
                return
            this.locationRegionLoading = true
            await this.getLocations('root')
                .then(response => {
                    if(response.length !== 0) {
                        this.locationRegionList = response
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.locationRegionLoading = false
                })
        },
        async getProjectStages() {
            if(!this.stageList.results.length) {
                try {
                    this.stageLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectStageModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.stageList.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.stageLoading = false
                }
            }
        },
        async getLocationDistricts() {
            if(this.locationDistrictList.length !== 0) 
                return
            this.locationDistrictLoading = true
            await this.getLocations(this.locationRegion)
                .then(response => {
                    if(response.length !== 0) {
                        this.locationDistrictList = response
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.locationDistrictLoading = false
                })
        },
        async getLocationAkimats() {
            if(this.locationAkimatList.length !== 0) 
                return
            this.locationAkimatLoading = true
            await this.getLocations(this.locationDistrict)
                .then(response => {
                    if(response.length !== 0) {
                        this.locationAkimatList = response
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.locationAkimatLoading = false
                })
        },
        async getLocationSettlements() {
            if(this.locationSettlementList.length !== 0) 
                return
            this.locationSettlementLoading = true
            await this.getLocations(this.locationAkimat)
                .then(response => {
                    if(response.length !== 0) {
                        this.locationSettlementList = response
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.locationSettlementLoading = false
                })
        },
        async getLocationVillages() {
            if(this.locationVillageList.length !== 0) 
                return
            this.locationVillageLoading = true
            await this.getLocations(this.locationSettlement)
                .then(response => {
                    if(response.length !== 0) {
                        this.locationVillageList = response
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.locationVillageLoading = false
                })
        },
        async getFundingSourcesList() {
            if(!this.fundingSourceList.results.length) {
                try {
                    this.sourceLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectFundingSourceModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.fundingSourceList.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.sourceLoading = false
                }
            }
        },

    },
    mounted(){
        eventBus.$on('add_invest_proejct', () => {
            this.visible = true
        })
    },
    beforeDestroy() {
        eventBus.$off('add_invest_proejct')
    }
}
</script>

<style lang="scss" scoped>
.equipment_list{
    &__item{
        &:not(:last-child){
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--border2);
        }
    }
}
.funding_sources_list{
    &__item{
        &:not(:last-child){
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--border2);
        }
    }
}
.invest_form{
    .form_block{
        padding: 15px;
        border: 1px solid var(--border2);
        border-radius: var(--borderRadius);
        margin-bottom: 20px;
        @media (min-width: 768px) {
            padding: 30px;
        }
        &__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            h3{
                font-size: 20px;
                color: #000000;
                font-weight: 400;
                margin: 0px;
            }
            .st{
                color: #000000;
                font-size: 18px;
                opacity: 0.3;
                padding-left: 15px;
                text-wrap: nowrap;
            }
        }
        &__attachments, &__add-project{
            margin-bottom: 20px;
        }
    }
    .footer_buttons{
        display: flex;
        align-items: center;
        &::v-deep{
            .ant-btn{
                &:not(:last-child){
                    margin-right: 10px;
                }
                &.ant-btn-lg{
                    height: 50px;
                    padding: 0 25px;
                }
            }
        }
    }
}
</style>